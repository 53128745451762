import { installLibrary } from '../../LibLoader/utilities/loadExternalLibrary.js';

export const install = (myDocument = document) => installLibrary('https://js.stripe.com/v3/', 'stripe-api', myDocument);

export const init = (stripeApiKey, myWindow = window) => new Promise((resolve, reject) => {
  myWindow.stripe = myWindow.Stripe(stripeApiKey);

  if (!myWindow.stripe) {
    return reject(new Error('Stripe initialization error'));
  }

  resolve(myWindow.stripe);
});

export const goToCheckout = (stripe, sessionId) => {
  return stripe.redirectToCheckout({
    sessionId,
  });
};
