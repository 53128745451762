<template>
  <Page class="page-shop page-shop-settings page-shop-settings-export">
    <template #title>Pagamento attivazione</template>

    <template #default v-if="!activationPaid">
      <FormContainer @submitForm="payActivation">
        <p>
          Il costo dell'attivazione di eziCommerce per il pacchetto che hai selezionato è di <strong>{{ activationPrice | currency }} €</strong>.
        </p>

        <FeedbackAlert v-if="error">Errore durante il pagamento! Riprovare</FeedbackAlert>

        <StripeCard v-model="validCreditCard" cardId="card-element" @init="getStripe" @cardInit="getCard" />

        <button class="btn btn-primary mt-4" type="submit" :disabled="!submittable || paying">Effettua il pagamento</button>
      </FormContainer>
    </template>
    <template #default v-else>
      <FeedbackAlert status="success">Pagamento effettuato correttamente! Ora il tuo account eziCommerce è attivo!</FeedbackAlert>
      <a v-if="shopUrl" class="btn btn-outline-primary" :href="shopUrl" target="_blank">Visualizza il tuo shop pubblico</a>
    </template>
  </Page>
</template>

<script>

import $oauth2 from '@/libs/OAuth2/services/auth.js';
import { formatCents } from '@/helpers/money.js';

import Page from '@/views/components/Private/Page.vue';
import FormContainer from '@/views/components/Form/FormContainer.vue';
import StripeCard from '@/views/components/Form/Widgets/StripeCard.vue';

export default {
  filters: {
    currency: formatCents,
  },
  components: {
    Page,
    FormContainer,
    StripeCard,
    FeedbackAlert: () => import('@/libs/Feedback/components/Alert.vue'),
  },
  data: () => ({
    error: false,
    paid: false,

    validCreditCard: false,
    stripe: null,
    cardElement: null,
    paymentStatus: false,

    paying: false,
  }),
  computed: {
    activationPrice () {
      return this.$oauth2.me?.shop?.activation_price || 0;
    },
    activationPaid () {
      return !!this.$oauth2.me?.shop?.activation_paid_at;
    },
    submittable () {
      return this.validCreditCard;
    },
    shopUrl () {
      return this.shop?.url;
    },
  },
  methods: {
    getStripe (res) {
      if (res.error) {
        this.$log.error(res.error);
        return;
      }

      this.stripe = res.stripe;
    },
    getCard (res) {
      if (res.error) {
        return;
      }

      this.cardElement = res.card;
    },
    payActivation () {
      this.paying = true;
      this.error = false;

      if (!this.stripe) {
        this.error = true;
        this.paying = false;

        return;
      }

      this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      }).then(result => this.stripePaymentMethodHandler(result)).catch(() => {
        this.error = true;
        this.paying = false;
      });
    },
    stripePaymentMethodHandler (result) {
      if (result.error) {
        this.paying = false;
        this.error = true;

        return;
      }

      this.$api.payShopActivation({
        paymentMethodId: result?.paymentMethod?.id || null,
      })
        .then(res => {
          this.handleServerResponse(res.data);
        })
        .catch(() => {
          this.error = true;
          this.paying = false;
        })
      ;
    },
    handleServerResponse (response) {
      if (response.error) {
        this.paying = false;
        this.error = true;

        return;
      }

      if (response.requiresAction) {
        this.stripe.handleCardAction(response.paymentIntentClientSecret).then(result => this.handleStripeJsResult(result)).catch(() => {
          this.paying = false;
          this.error = true;
        });

        return;
      }

      this.paid = true;
      this.paying = false;
      this.$oauth2.refreshMe();
    },
    handleStripeJsResult (result) {
      if (result.error) {
        this.paying = false;
        this.error = true;

        return;
      }

      this.$api.payShopActivation({
        paymentIntentId: result?.paymentIntent?.id || null,
      }).then(res => {
        this.handleServerResponse(res.data);
      }, () => {
        this.paying = false;
        this.error = true;
      });
    },
  },
  beforeRouteEnter (to, from, next) {
    const me = $oauth2.me;
    if (!me || !me.shopDataReady) {
      next(from);

      return;
    }

    const shop = me.shop;
    if (!shop) {
      next(from);

      return;
    }

    if (shop.activation_paid_at) {
      next({ name: 'home' });

      return;
    }

    next();
  },
};

</script>
